import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h1>
    <p>{`You finished the book! You're my new favorite person `}{`\\`}{`o/`}</p>
    <p>{`You've built a really big interactive data visualization, a few silly
animations, learned the basics of Redux and MobX, looked into rendering your
stuff on canvas, and considered React alternatives.`}</p>
    <p>{`Right now you might be thinking `}<em parentName="p">{`"How the hell does this all fit together to
help me build interactive graphs and charts?"`}</em>{` You've learned the building
blocks!`}</p>
    <p>{`First, you learned the basics. How to make React and D3 like each other, how to
approach rendering, what to do with state. Then you built a big project to see
how it fits together.`}</p>
    <p>{`Then you learned animation basics. How to approach updating components with
fine grained control and how to do transitions. You learned that tricks from
the gaming industry work here, too.`}</p>
    <p>{`You're an expert React slinger now! I think that's awesome.`}</p>
    <p>{`Even if you didn't build the projects, you still learned the theory. When the
right problem presents itself, you'll think of this book and remember what to
do. Or at least know where to look to find a solution.`}</p>
    <p>{`At the very least, you know where to look to find a solution. This book is
yours forever.`}</p>
    <p>{`I hope you have enjoyed this book. Tweet `}<a parentName="p" {...{
        "href": "https://twitter.com/Swizec"
      }}>{`\\@Swizec`}</a>{`
and tell me what you're building. 😄`}</p>
    <p>{`Questions? Poke me on twitter. I’m `}<a parentName="p" {...{
        "href": "https://twitter.com/Swizec"
      }}>{`\\@Swizec`}</a>{`. Or
send me an email to `}<a parentName="p" {...{
        "href": "mailto:hi@swizec.com"
      }}>{`hi@swizec.com`}</a>{`. I read every email
and reply on a best effort basis.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      